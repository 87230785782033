<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_select">
            <el-select v-model="value" clearable placeholder="请选择批次" size="small">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </div>
        <el-table 
            :data="tableData.slice((currentPage-1)*pageSize,currentPage*pageSize)" 
            style="width: 100%;height:97%"
            stripe
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="index" width="50" label="#"></el-table-column>
            <el-table-column prop="project_number" label="项目编号" width="150px"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="isYouth" label="青年红色筑梦之旅项目" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.isYouth==0?'是':'否'}}</span>
                </template>
            </el-table-column>   
            <el-table-column prop="priname" label="项目负责人"></el-table-column>
            <el-table-column prop="people" label="项目级别" width="100">
                <template>
                    <span>国家级</span>
                </template>
            </el-table-column>
            <el-table-column prop="tname" label="指导教师"></el-table-column>
            <el-table-column prop="money" label="经费总额（元）" width="120px">
                <template slot-scope="scope">
                    <el-tag disable-transitions @click="dialogVisible=true" style="cursor: pointer;">{{scope.row.money}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="170px">
                <template slot-scope="scope">
                    <el-button type="primary" @click="handleClick(scope.row)" size="mini">查看</el-button>
                    <el-button @click="dialogVisibleForm=true" size="mini">评语</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-sizes="[10, 15, 20]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="tableData.length">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <div class="tcation_Popup">
            <el-dialog
                title="经费开销详情"
                :visible.sync="dialogVisible"
                width="800px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-table 
                    :data="fundList" 
                    style="width: 100%"
                    stripe
                    :cell-style="{'text-align':'center'}"
                    :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
                >   
                    <el-table-column type="index" width="100" label="#"></el-table-column>
                    <el-table-column prop="name" label="名称"></el-table-column>
                    <el-table-column prop="money" label="金额"></el-table-column>
                    <el-table-column prop="bill" label="票据">
                        <template slot-scope="scope">
                            <img :src="scope.row.bill" alt="" width="100" height="80">
                        </template>
                    </el-table-column>
                </el-table>
            </el-dialog>
            <el-dialog
                title="指导教师意见"
                :visible.sync="dialogVisibleForm"
                width="650px"
                :before-close="handleClose"
                :show-close="false">
                <i class="el-icon-close closei" @click="closei()"></i>
                <el-form :model="Form" :rules="rules" ref="Form" label-width="100px" class="demo-ruleForm">
                    <el-form-item prop="outcome" label="意见内容" style="width:100%">
                        <el-input type="textarea" :rows="6" v-model="Form.outcome"></el-input>
                    </el-form-item>
                </el-form>
                <span class="dialog-footer" slot="footer">
                    <el-button size="small" type="primary" @click="addmbers('Form')">确 定</el-button>
                    <el-button size="small" @click="closei()">取 消</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data () {
        return {
            options:[
                {
                    value: '1',
                    label: '2018'
                },
                {
                    value: '2',
                    label: '2019'
                },
                {
                    value: '3',
                    label: '2020'
                },
            ],
            value:'',
            pageSize:10,
            totalnum:0,
            currentPage:1,
            tableData:[],
            fundList:[],
            dialogVisible:false,
            dialogVisibleForm:false,
            Form:{},
            rules:{},
        }
    },
    methods: {
        getapp(){
            // this.axios.teacheritemlist({
            //     params:{
            //         tid:this.$store.state.teaId
            //     }
            // }).then(res=>{
            //     if(res.data.code==200){
            //         this.tableData = res.data.rows
            //     }else{
            //         this.tableData = []
            //     }
            // }).catch(err=>{

            // })
        },
        handleClose(done){

        },
        //关闭弹窗
        closei(){
            this.dialogVisible = false
            this.dialogVisibleForm = false
        },
        //查看项目详情
        handleClick(rows){
            this.$router.push({name:'Viewteacherreport',query:{itemId:rows.itmId}})
        },
        handleSizeChange(val){
            this.pageSize = val;
        },
        handleCurrentChange(val){
            this.currentPage = val;
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.tcation_Popup .el-dialog__header{
    padding: 0 0 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #fff;
    border-bottom: 1px solid #eaecee;
    background: #409EFF;
}
.tcation_Popup .el-dialog__title{
    font-size: 14px;
    color: #fff;
}
.tcation_Popup .el-table__body-wrapper{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-table__empty-block{
    min-height: 300px!important;;
    height: 300px!important;
}
.tcation_Popup .el-dialog__footer{
    text-align: center;
}
.tcation_Popup .el-textarea__inner{
    resize: none;
    width: 450px;
}
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 357px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 357px);
}
</style>